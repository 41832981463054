import * as Sentry from "@sentry/gatsby"

Sentry.init({
  dsn: "https://de0c142fd2d84e4cb8c8e4f312d8d709@o29651.ingest.sentry.io/4504523372298240",
  integrations: [Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
